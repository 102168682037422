<template>
    <div>
        <div class="flex items-center justify-between bg-white rounded-lg">
            <!-- click to go to push notification view -->
            <RouterLink to="/push-notification">
                <TooglePushNotification />
            </RouterLink>

            <!-- Logo e texto "AFILIADO" centralizados -->
            <div class="flex items-center m-auto">
                <img src="@/assets/logo.png" alt="Hypez Logo" class="w-[84px] h-[20px]" />
                <span
                    class="ml-2 px-2 py-1 bg-[#35DFD229] text-[#35DFD2] text-[8.57px] font-semibold rounded-full border border-[#35DFD229]">
                    AFILIADO
                </span>
            </div>
            
            <img @click="refreshPage" ref="refreshButton" src="@/assets/refresh.png" class="p-1 rounded-full p-1 bg-[#F5DFFF] w-[26px] h-[26px]" />
            
        </div>

        <div class="flex items-center pt-[28px]">
            <!-- Alinhar o texto à esquerda -->
            <div class="text-[#000000] text-[20px] text-left leading-[22px]">
                <p >
                    Bem-vindo de volta,
                    <br> <b v-if="username" class="font-bold capitalize">{{ username }}!</b>
                    <span v-else class="block w-24 h-4 bg-gray-300 rounded-md animate-pulse mt-2"></span>
                </p>
            </div>
            <img class="ml-auto w-[51px] h-[51px] rounded-full" :src="['https://ui-avatars.com/api/?background=F7F2FF&color=743BCD5C&name='+ username]" />
        </div>
        <!-- Alinhar o texto à esquerda -->
        <p class="mt-2 text-[#929292] text-[12px] text-left leading-[22px]">
            Veja o que está acontecendo hoje
        </p>
    </div>
</template>

<script>
  import axios from 'axios';
  import TooglePushNotification from './TooglePushNotification.vue';
  import { RouterLink } from 'vue-router';
  
  export default {
    name: "WelcomeBanner",
    components: {
      TooglePushNotification,
      RouterLink
    },
    data() {
      return {
        username: ''
      };
    },
    async created() {
      await this.fetchUsername();
    },
    methods: {
      refreshPage() {
        this.$refs.refreshButton.classList.add('animate-spin');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      async fetchUsername() {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        if (token) {
          try {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/dashboard/api/user/`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            if (response.status === 200) {
              this.username = response.data.username;
            }
          } catch (error) {
            if (error.response && error.response.data.code === 'token_not_valid') {
              try {
                const refreshResponse = await axios.post(`${process.env.VUE_APP_API_URL}/accounts/token/refresh/`, {
                  refresh: refreshToken
                });
                const newToken = refreshResponse.data.access;
                localStorage.setItem('token', newToken);
                axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                await this.fetchUsername();
              } catch (refreshError) {
                console.error('Erro ao atualizar o token:', refreshError);
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                this.$router.push('/');
              }
            } else {
              console.error('Erro ao obter o nome de usuário:', error);
            }
          }
        }
      }
    }
  };
  </script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */
.animate-spin {
  animation: spin 1s linear infinite;
}
</style>