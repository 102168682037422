<template>
    <div class="mt-5 rounded bg-[#F7F2FF] py-4 px-2 flex flex-col pt-[22px] relative">
        <div class="absolute top-0 text-[10px] right-0 mt-2 mr-2 text-[#743BCD5C] font-bold">
            👁️ Admin
        </div>
      <div class="text-[#000000] text-[14px] text-left font-bold">
        Conversões no Modo Hack
      </div>
      
      <CalendarFilterComponent 
        @filter-applied="handleFilterApplied" 
        :totalDeposits="totalDeposits" :notMoney="true" />
      
      <Bar :options="chartOptions" :data="computedChartData" class="mt-4"
        v-if="chartData.labels.length > 0 && chartData.datasets[0].data.length > 0" />
      <span v-else class="block w-full h-[150px] bg-gray-300 rounded-md animate-pulse mt-2"></span>
    </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  } from 'chart.js';
  import api from '@/services/api';
  import CalendarFilterComponent from './CalendarFilterComponent.vue';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: "ModoHackConversion",
    props: ['affiliate_id'],
    components: { 
      Bar,
      CalendarFilterComponent
    },
    data() {
      return {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Depósitos',
              data: [],
              backgroundColor: '#8E44AD',
              borderRadius: 15,
              maxBarThickness: 21,
              hoverBackgroundColor: '#F8CEFd',
            }
          ]
        },
        chartOptions: {
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              borderColor: '#FFF',
              grid: {
                display: false
              },
              beginAtZero: true,
              ticks: {
                callback: function(value) {
                  return `${value}`;
                }
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return `${context.parsed.y}`;
                }
              }
            }
          }
        },
        totalDeposits: 0,
        startDate: null,
        endDate: null
      };
    },
    async created() {
      await this.fetchDepositChartData();
    },
    watch: {
      affiliate_id: {
        handler() {
          this.fetchDepositChartData(this.startDate, this.endDate);
        },
        immediate: true
      }
    },
    computed: {
      computedChartData() {
        return {
          labels: this.chartData.labels,
          datasets: [
            {
              label: 'Conversões',
              data: this.chartData.datasets[0].data,
              backgroundColor: '#8E44AD',
              borderRadius: 15,
              maxBarThickness: 21,
              hoverBackgroundColor: '#F8CEFd',
            }
          ]
        };
      }
    },
    methods: {
      async fetchDepositChartData(start_date = null, end_date = null) {
        console.log('Fetching Deposit Chart Data:', start_date, end_date);
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        this.totalDeposits = 0;
        try {
          const params = {};
          if (start_date && end_date) {
            params.start_date = start_date;
            params.end_date = end_date;
          }
          if (this.affiliate_id) {
            params.affiliate_id = this.affiliate_id;
          }
          const response = await api.get('/dashboard/api/chart-customer-lifetime-activation/', { params });
          
          console.log(response)
          if (response.status === 200) {
            const data = response.data;
            this.chartData.labels = data.labels;
            this.chartData.datasets[0].data = data.data;
            
            this.totalDeposits = data.data.reduce((sum, value) => sum + value, 0);
          }
  
        } catch (error) {
          console.error('Erro ao obter os dados do gráfico de depósitos:', error);
        }
      },
      handleFilterApplied(dateRange) {
        if (dateRange && dateRange.start && dateRange.end) {
          const start = dateRange.start.toISOString().split('T')[0];
          const end = dateRange.end.toISOString().split('T')[0];
          this.startDate = start;
          this.endDate = end;
          this.fetchDepositChartData(start, end);
        } else {
          console.error('Data Range inválido:', dateRange);
          this.fetchDepositChartData();
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Adicione estilos adicionais aqui, se necessário */
  </style>
  