<template>
  <div class="">
    <div class="w-full max-w-md">
      <WelcomeBanner />

      <TopAffiliates v-if="is_staff" @affiliate-selected="changeAffiliate" />
      <ModoHackConversion v-if="is_staff" @affiliate-selected="changeAffiliate" />
      <DropdownAffiliate v-if="is_staff" @affiliate-selected="changeAffiliate" />
      
      <AfiliadoBanner :affiliate_id="affiliate_id" />
      <DailyCards  :affiliate_id="affiliate_id" />
      <FtdChart  :affiliate_id="affiliate_id" />
      <DepositChart  :affiliate_id="affiliate_id" />
      <LastUsers  :affiliate_id="affiliate_id" />
    </div>
  </div>
</template>

  
  <script>
  import WelcomeBanner from '../components/WelcomeBanner.vue';
  import AfiliadoBanner from '../components/AfiliadoBanner.vue';
  import DailyCards from '../components/DailyCards.vue';
  import DepositChart from '../components/DepositChart.vue';
  import LastUsers from '../components/LastUsers.vue';
  import FtdChart from '../components/FtdChart.vue';
  import DropdownAffiliate from '../components/DropdownAffiliate.vue';
  import TopAffiliates from '../components/TopAffiliates.vue';
  import api from '@/services/api';
  import ModoHackConversion from '../components/ModoHackConversion.vue';

  export default {
    name: 'DashboardView',
    components: {
      WelcomeBanner,
      AfiliadoBanner,
      DailyCards,
      DepositChart,
      LastUsers,
      FtdChart,
      DropdownAffiliate,
      TopAffiliates,
      ModoHackConversion,
    },
    data() {
      return {
        affiliate_id: null,
        is_staff: false,
      };
    },
    created() {
      this.getUser();
    },
    methods: {
      changeAffiliate(affiliate_id) {
        console.log('Affiliate Selected:', affiliate_id);
        this.affiliate_id = affiliate_id;
      },
      // dashboard/api/user/
      getUser() {
        // Implemente a lógica para obter o usuário logado
        api.get('/dashboard/api/user/')
          .then(response => {
            this.is_staff = response.data.is_staff;
            this.affiliate_id = response.data.affiliate_id;
          })
          .catch(error => {
            console.error('Erro ao obter o usuário logado:', error);
          });
      },
    },
  }
  </script>
  
  <style scoped>
  /* Adicione estilos adicionais aqui, se necessário */
  </style>
  