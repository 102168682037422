<template>
  <LoginForm />
  <!-- <AddToHomeScreen v-else :deferredPrompt="deferredPrompt" /> -->
</template>

<script>
  import LoginForm from '../components/LoginForm.vue';
  // import AddToHomeScreen from '../components/AddToHomeScreen.vue';

  export default {
    name: 'LoginView',
    components: {
      LoginForm,
      // AddToHomeScreen
    },
    props: ['deferredPrompt'],
    data() {
      return {
        isPWA: false,
        visitorId: '',
      };
    },
    mounted() {
      this.checkPWAStatus();
    },
    methods: {
      checkPWAStatus() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          console.log('Running as PWA');
          this.isPWA = true;
          
        } else {
          console.log('Not running as PWA');
          this.isPWA = false;
        }
      },
    
    }
  }
  </script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */
</style>