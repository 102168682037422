<template>
    <AddToHomeScreen :deferredPrompt="deferredPrompt" />
</template>

<script>
    import AddToHomeScreen from '../components/AddToHomeScreen.vue';

    export default {
        name: 'LoginView',
        components: {
            AddToHomeScreen
        },
        props: ['deferredPrompt'],
    }
</script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */
</style>