<template>
    <div class="w-full max-w-md add-to-home-screen-container m-auto flex flex-col items-center text-center p-4">
      <div class="phone-preview mb-4 min-h-[220px] align-center">
        <img :src="currentImage" @click="nextStep" alt="Phone with app icon" class="phone-image">
      </div>
      <div class="w-full flex flex-col items-center">
        <div class="step-label text-white bg-[#743bcd] w-[90px] rounded-full pt-0 pb-0">PASSO A PASSO</div>
      </div>
      
      <div v-if="isIOS">
        <div v-if="currentStep === 1" class="step-content">
          <h2 class="title w-full ">Você precisa adicionar o ícone na sua tela inicial</h2>
          <p class="description">
            Acesse o aplicativo com mais facilidade adicionando ele na tela inicial do seu celular.
          </p>
        </div>
        <div v-if="currentStep === 2" class="step-content">
          <h2 class="title w-full">Passo 01</h2>
          <p class="description">
            Com o aplicativo aberto no seu navegador, Clique no ícone compartilhar, circulado na imagem acima
          </p>
        </div>
        <div v-if="currentStep === 3" class="step-content">
          <h2 class="title w-full">Passo 02</h2>
          <p class="description">
            Em seguida, desça toda a página e clique na última opção. “Adicionar a tela de início”
          </p>
        </div>
        <div v-if="currentStep === 4" class="step-content">
          <h2 class="title w-full">Passo 03</h2>
          <p class="description">
            Feito isso, basta clicar em “Adicionar” no canto superior direito da página.
          </p>
        </div>
      </div>

      <div v-else>
        <div v-if="currentStep === 1" class="step-content">
          <h2 class="title w-full">{{ browserDetails.name }} Você precisa adicionar o ícone na sua tela inicial</h2>
          <p class="description">
            Acesse o aplicativo com mais facilidade adicionando ele na tela inicial do seu celular.
          </p>
        </div>
      </div>
      <button @click="nextStep" class="start-button text-white mt-4 w-full">
        {{ currentStep < maxSteps ? 'Próximo' : isIOS ? 'Voltar ao início' : 'Instalar' }}
      </button>
      <div class="flex justify-center items-center mt-2 space-x-20 w-full">
        <div class="flex justify-center mt-4">
          <div v-for="step in maxSteps" :key="step" :class="['page-indicator', currentStep === step ? 'page-indicator-active' : 'page-indicator-inactive']"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  const { detect } = require('detect-browser');
//   const browserDetails = detect();

  export default {
    name: 'AddToHomeScreen',
    props: {
      deferredPrompt: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        browserDetails: detect(),
        currentStep: 1,
        isIOS: detect().name === 'ios',
        isSamsung: detect().name === 'samsung',
        isFirefox: detect().name === 'firefox',
        isChrome: detect().name === 'chrome',
        localDeferredPrompt: null
      };
    },
    computed: {
      currentImage() {
        if (!this.isIOS && this.currentStep === 1) {
          return require('@/assets/ios/ios-1.png');
        }
        switch (this.currentStep) {
          case 1:
            return require('@/assets/ios/ios-1.png');
          case 2:
            return require('@/assets/ios/ios-2.png');
          case 3:
            return require('@/assets/ios/ios-3.png');
          case 4:
            return require('@/assets/ios/ios-4.png');
          default:
            return require('@/assets/ios/ios-1.png');
        }
      },
      maxSteps() {
        return this.isIOS ? 4 : 1;
      }
    },
    watch: {
      deferredPrompt: {
        immediate: true,
        handler(newVal) {
          this.localDeferredPrompt = newVal;
        }
      }
    },
    methods: {
      nextStep() {
        if (this.currentStep < this.maxSteps) {
          this.currentStep++;
        } else if (this.isIOS) {
          this.currentStep = 1;
        } else {
          this.startProcess();
        }
      },
      startProcess() {
        if (this.localDeferredPrompt) {
          // Chama o prompt no próximo ciclo de evento para garantir que ele ocorra após o clique
          this.localDeferredPrompt.prompt();
          this.localDeferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            this.localDeferredPrompt = null; // Reiniciando o localDeferredPrompt após o uso
          });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .phone-preview {
    width: 100%;
  }
  
  .phone-image {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  
  .step-label {
    font-size: 8px;
    
    margin-bottom: 8px;
  }
  
  .title {
    font-size: 18px;
    font-weight: bold;
    
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 13px;
    
    margin-bottom: 20px;
    line-height: 15px;
  }
  
  .start-button {
    background-color: #743bcd;
    
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .start-button:hover {
    background-color: #743bcd;
  }
  
  .cancel-button {
    background: none;
    border: none;
    color: #797180;
    cursor: pointer;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.25px;
    text-align: center;
  }
  
  .page-indicator {
    width: 20px;
    height: 3px;
    border-radius: 2.5px;
    margin: 0 5px;
  }
  
  .page-indicator-active {
    background-color: #2547C0;
  }
  
  .page-indicator-inactive {
    background-color: #D4D9E82E;
  }
  </style>
  