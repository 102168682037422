<template>
    <PushNotification />
</template>

<script>
    import PushNotification from '../components/PushNotification.vue';

    export default {
        name: 'PushNotificationView',
        components: {
            PushNotification
        },
    }
</script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */
</style>