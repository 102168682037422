<template>
  <div class="flex flex-col pt-[22px]">
    <div class="text-[#000000] text-[14px] text-left font-bold">
      Últimos usuários cadastrados
    </div>
    <div class="grid grid-cols-1 gap-2 mt-2 text-left w-full">
      <div class="grid grid-cols-3 bg-[#F9F4FF] text-[10px] text-[#929292] p-2 rounded">
        <span>Usuário</span>
        <span>E-mail</span>
        <span>Criado em</span>
      </div>
      <div v-for="user in users" :key="user.id" class="grid grid-cols-3 p-2 border-b text-[11px]">
        <div>{{ user.user_name }}</div>
        <div>{{ user.user_email }}</div>
        <div>{{ user.created_at }}</div>
      </div>
      <span v-if="users.length === 0" class="block w-full h-[450px] bg-gray-300 rounded-md animate-pulse mt-2"></span>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: "LastUsers",
  props: ['affiliate_id'],
  data() {
    return {
      users: []
    };
  },
  async created() {
    await this.fetchRecentUsers();
  },
  watch: {
    affiliate_id: {
      handler() {
        this.fetchRecentUsers();
      },
      immediate: true
    }
  },
  methods: {
    async fetchRecentUsers() {
      // Exibir animação de carregamento
      this.users = [];
      try {
        const params = {};
        if (this.affiliate_id) {
          params.affiliate_id = this.affiliate_id;
        }
        const response = await api.get('/dashboard/api/recent-customers/', { params });
        // Parse created_at para o formato dd/MM/yyyy hh:mm:ss 24 horas
        response.data.forEach(user => {
          user.created_at = new Date(user.created_at).toLocaleString('pt-BR');
        });
        this.users = response.data;
      } catch (error) {
        console.error('Erro ao obter os últimos usuários cadastrados:', error);
      }
    }
  }
};
</script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */
</style>
