import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

// Interceptor de requisição para adicionar o token de autenticação
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Interceptor de resposta para tratar o refresh do token
api.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;

  // Verifica se o erro é 401 e se o token está expirado
  if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/accounts/api/token/refresh/`, {
          refresh: refreshToken
        });

        const newToken = response.data.access;
        localStorage.setItem('token', newToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

        // Repete a solicitação original com o novo token
        return axios(originalRequest);
      } catch (refreshError) {
        console.error('Erro ao atualizar o token:', refreshError);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        // Redireciona para a página de login se o refresh falhar
        window.location.href = '/';
        return Promise.reject(refreshError);
      }
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/';
    }
  }
  return Promise.reject(error);
});

export default api;
