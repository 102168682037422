
<template>
    <div class="mt-5 bg-[#F7F2FF] py-4 px-2 flex align-center rounded-[9px] relative">
        <div class="absolute top-0 text-[10px] right-0 mt-2 mr-2 text-[#743BCD5C] font-bold">
            👁️ Admin
        </div>
        <div class="w-full text-left">
            <label for="affiliate-select" class="text-[12px]">
                Selecione um afiliado: {{ loggedUserAffiliateId }}
            </label>
            <select id="affiliate-select" v-model="selectedAffiliate" @change="changeAffiliate" class="p-2 rounded w-full">
                <option 
                    v-for="user in users" 
                    :key="user.username" 
                    :value="user.affiliate_id">
                    {{ user.username }} - {{ user.affiliate_id }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
  import api from '@/services/api';
  
  export default {
    name: 'DropdownAffiliate',
    data() {
      return {
        users: [],
        loggedUserAffiliateId: null,
      };
    },
    created() {
      this.fetchUsers();
    },
    methods: {
      async fetchUsers() {
        const response = await api.get('/dashboard/api/list-users-with-affiliate/');
        this.users = response.data;
      },
      changeAffiliate() {
        this.$emit('affiliate-selected', this.selectedAffiliate);
      }
    }
  };
  </script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
    width: 200px;
}

.dropdown-button {
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
}

.dropdown-content {
    display: block;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
</style>