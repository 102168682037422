<template>
    <div class="mt-5 rounded bg-[#F7F2FF] py-4 px-2 flex flex-col pt-[22px] relative">
        <div class="absolute top-0 text-[10px] right-0 mt-2 mr-2 text-[#743BCD5C] font-bold">
            👁️ Admin
        </div>
        <div class="text-[#000000] text-[14px] text-left font-bold mb-2">
            Top Afiliados
        </div>
        <FilterComponent @filter-applied="handleFilterApplied" />
        <div class="grid grid-cols-1 gap-3 mt-2 text-left w-full">
            <div class="grid grid-cols-5 bg-[#F9F4FF] text-[10px] text-[#929292] p-2 rounded">
                <span>Afiliado</span>
                <span>Total (R$)</span>
                <span>Depósitos</span>
                <span>Indicados</span>
                <span>FTD</span>
            </div>
            <div v-for="user in users" :key="user.id" class="grid grid-cols-5 p-2 border-b text-[11px]">
                <div>{{ user.username }}</div>
                <div>{{ user.total_deposit_value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }}</div>
                <div>{{ user.deposit_count }}</div>
                <div>{{ user.indicated_users_count }}</div>
                <div>{{ user.ftd_count }}</div>
            </div>
            <span v-if="users.length === 0"
                class="block w-full h-[450px] bg-gray-300 rounded-md animate-pulse mt-2"></span>
        </div>
    </div>
</template>

<script>
  import api from '@/services/api';
  import FilterComponent from './FilterComponent.vue';
  
  export default {
    name: "TopAffiliates",
    props: ['affiliate_id'],
    components: { FilterComponent },
    data() {
      return {
        users: []
      };
    },
    async created() {
      await this.fetchRecentUsers();
    },
    methods: {
        async fetchRecentUsers(start, end) {
            // Exibir animação de carregamento
            this.users = [];
            try {
                const params = {
                    start_date: start,
                    end_date: end
                };
                if (this.affiliate_id) {
                params.affiliate_id = this.affiliate_id;
                }
                const response = await api.get('/dashboard/api/top-affiliates/', { params });
                this.users = response.data;
            } catch (error) {
                console.error('Erro ao obter os últimos usuários cadastrados:', error);
            }
        },
        handleFilterApplied(dateRange) {
            if (dateRange && dateRange.start && dateRange.end) {
                const start = dateRange.start.toISOString().split('T')[0];
                const end = dateRange.end.toISOString().split('T')[0];
                this.startDate = start;
                this.endDate = end;
                this.fetchRecentUsers(start, end);
            } else {
                console.error('Data Range inválido:', dateRange);
                this.fetchRecentUsers();
            }
        }
      
    }
  };
  </script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */
</style>