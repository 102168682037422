<template>
    <div>
      <div class="flex items-center justify-between">
        <div class="text-[#000000] text-[14px] text-left font-bold">
          <span v-if="appliedDateRange.length === 2">
            De {{ formatDate(appliedDateRange[0]) }} até {{ formatDate(appliedDateRange[1]) }}
          </span>
          <span v-else>
            Hoje
          </span>
        </div>
        <div class="relative">
          <button @click="toggleDateRange" class="flex items-center">
            <img src="@/assets/filter.svg" alt="Filter" />
          </button>
        </div>
      </div>
  
      <div v-if="showDateRange" class="flex px-2 py-2 z-10 mt-2 mb-2 rounded border-[1px] bg-white">
        <DatePicker 
            v-model:value="dateRange" 
            range 
            :editable="true" 
            :clearable="true" 
            format="YYYY-MM-DD"
            :lang="lang"
            input-class="readonly-input"
            ref="datepicker"
            class="w-full pt-2 pb-2"
            placeholder="Data de início e fim"
        />
        <button @click="applyFilter" class="ml-2 bg-blue-500 text-white py-1 px-3 rounded">
          Aplicar
        </button>
      </div>
    </div>
  </template>
  
  <script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import pt from 'vue-datepicker-next/locale/pt';
import { format } from 'date-fns';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      showDateRange: false,
      dateRange: [], // Inicializado como array
      appliedDateRange: [], // Armazena o intervalo de datas aplicado
      format: 'DD-MM-YYYY',
      lang: pt // Alterado para 'pt'
    };
  },
  methods: {
    toggleDateRange() {
      this.showDateRange = !this.showDateRange;
    },
    applyFilter() {
      console.log('Current dateRange:', this.dateRange); // Adicionando log para verificação
      if (this.dateRange && this.dateRange.length === 2) {
        this.appliedDateRange = [...this.dateRange]; // Armazena o intervalo de datas aplicado
        const [start, end] = this.dateRange;
        console.log('Date Range Selected:', start, end);
        this.$emit('filter-applied', { start, end });
      } else {
        console.log('No Date Range Selected');
        this.appliedDateRange = []; // Reseta o intervalo de datas aplicado se nenhum intervalo for selecionado
      }
      this.showDateRange = false;
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy');
    },
    disableKeyboard(event) {
      event.target.blur();
    }
  }
};
</script>

<style scoped>
/* Estilos específicos para o componente, se necessário */
.readonly-input {
  pointer-events: none;
}
</style>

