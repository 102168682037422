<template>
    <div class="text-left bg-white p-4 rounded-[5px] shadow relative">
      <div class="absolute top-0 right-0 mt-2 mr-2 font-bold">
        <!-- Imagem path from image variable @/assets/vairable-here -->
        <img :src="require(`@/assets/${image}`)" />
      </div>
      <!-- Conteúdo do card de pontuação -->
      <div class="mt-5">
        <p v-if="!loading" class="text-[24px] font-bold">{{ value }}</p>
        <span v-else class="block w-24 h-[34px] bg-gray-300 rounded-md animate-pulse mt-2"></span>
        <p class="text-[11.5px]">{{ text }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "UserScoreCards",
    props: {
      value: {
        type: Number,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      image: {
        type: String,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      }
    }
  };
  </script>
  
  <style scoped>
  /* Estilos específicos para o card, se necessário */
  </style>
  