<template>
    <div>
        <div class="flex items-center justify-between mt-2 w-full">
            <div class="flex-1">
                <div v-if="totalDeposits" class="text-[#26272A] text-[26.89px] text-left font-bold mt-1">
                  <span v-if="notMoney">
                    {{ totalDeposits }}
                  </span>
                  <span v-else>
                    R$ {{ totalDeposits.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }}
                  </span> 
                </div>
                <span v-else class="block w-36 h-[40px] bg-gray-300 rounded-md animate-pulse mt-2"></span>
            </div>
            <div class="ml-auto">
                <button @click="toggleDateRange" class="flex items-center">
                    <img src="@/assets/calendar-filter.svg" alt="Filter" class="w-12 h-12" />
                </button>
            </div>
        </div>


        <div v-if="showDateRange" class="flex p-4 z-10 mt-4 border-b-[1px] mb-2">
            <DatePicker v-model:value="dateRange" range :editable="true" :clearable="true" format="YYYY-MM-DD"
                :lang="lang" input-class="readonly-input" ref="datepicker" class="w-full"
                placeholder="Data de início e fim" />
            <button @click="applyFilter" class="ml-2 bg-blue-500 text-white py-1 px-3 rounded">
                Aplicar
            </button>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import pt from 'vue-datepicker-next/locale/pt';
import { format } from 'date-fns';

export default {
  name: 'CalendarFilterComponent',
  components: {
    DatePicker
  },
  props: {
    notMoney: {
      type: Boolean,
      required: false,
      default: false
    },
    totalDeposits: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showDateRange: false,
      dateRange: [], // Inicializado como array
      appliedDateRange: [], // Armazena o intervalo de datas aplicado
      format: 'DD-MM-YYYY',
      lang: pt // Alterado para 'pt'
    };
  },
  methods: {
    toggleDateRange() {
      this.showDateRange = !this.showDateRange;
    },
    applyFilter() {
      console.log('Current dateRange:', this.dateRange); // Adicionando log para verificação
      if (this.dateRange && this.dateRange.length === 2) {
        this.appliedDateRange = [...this.dateRange]; // Armazena o intervalo de datas aplicado
        const [start, end] = this.dateRange;
        console.log('Date Range Selected:', start, end);
        this.$emit('filter-applied', { start, end });
      } else {
        console.log('No Date Range Selected');
        this.appliedDateRange = []; // Reseta o intervalo de datas aplicado se nenhum intervalo for selecionado
      }
      this.showDateRange = false;
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy');
    },
    disableKeyboard(event) {
      event.target.blur();
    }
  }
};
</script>

<style scoped>
/* Estilos específicos para o componente, se necessário */
.readonly-input {
    pointer-events: none;
}
</style>
