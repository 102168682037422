<template>
  <router-view 
    :deferredPrompt="deferredPrompt" 
    class="p-4 flex flex-col items-center justify-center min-h-screen">
  </router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      deferredPrompt: null
    };
  },
  methods:{
    checkPWAStatus() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          console.log('Running as PWA');
          this.isPWA = true;
          
        } else {
          console.log('Not running as PWA');
          this.isPWA = false;
        }
      },
  },
  mounted(){
    //beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // console.log(e)
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
