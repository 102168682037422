<template>
    <div class="push-notification-container flex flex-col items-center text-center p-4">
      <div class="notification-preview mb-4">
        <!-- <img :src="pushImage" alt="Push Notification Preview" class="notification-image"> -->
      </div>
      
      <div class="flex justify-center mb-4">
        <div class="custom-emoji-icon rounded-full p-4">
          <span class="text-2xl">🔔</span>
        </div>
      </div>
      <div class="notification-content">
        <h2 class="title w-full">Ative as Notificações</h2>
        <p class="description">
          Permita notificações para ficar por dentro de tudo que acontece no app
        </p>
      </div>
      <button v-if="!isTokenActive" @click="enableNotifications" class="start-button mt-4 w-full">
        Ativar notificações
      </button>
      <button v-else @click="disableNotifications" class="disable-button mt-4 w-full">
        Desabilitar notificações
      </button>
      <button @click="disableNotifications" class="cancel-button mt-2 w-full">
        Não, obrigado
      </button>
    </div>
  </template>
  
  <script>
  import { messaging, getToken } from '../firebaseConfig';
//   import { getDatabase, ref, set } from "firebase/database";
  import api from '@/services/api';
  // import cookie
  import { getCookie, setCookie, deleteCookie } from '@/services/cookie';

  export default {
    name: 'PushNotification',
    data() {
      return {
        pushImage: '',
        isTokenActive: false
      };
    },
    mounted(){
        this.activeStatusToken();
        console.log(222222222222, getCookie('fcm-token'))
    },
    methods: {
      saveTokenToDatabase(token) {
        console.log('Token: ', token);
          
        api.post('/dashboard/api/fcm-token/', { token })
        .then(response => {
            console.log('Token enviado para a API com sucesso.', response);
            // save to cookie never expire
            setCookie('fcm-token', token, 300);
        }).catch(error => {
            console.error('Erro ao enviar o token para a API:', error);
        });
      },
      activeStatusToken() {
        const tokenCookie = getCookie('fcm-token');
        if (tokenCookie) {
            console.log('Token ativo no cookie:', tokenCookie);
            this.isTokenActive = true;
        } else {
            console.log('Token não ativo no cookie:', tokenCookie);
            this.isTokenActive = false;
        }        
      },
      async requestNotificationPermission() {
        try {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get registration token. Initially this makes a network call, once retrieved
            // subsequent calls to getToken will return from cache.
            getToken(messaging, { vapidKey: 'BBXU8VejhyacmsVb_eUe-iN9r-2LbBP5quvO8zH2kcJ9828VYsMGLVczvJgeEvW0LiZ3yV0Kleh9TpEG3hUpSrk' })
              .then((currentToken) => {
                if (currentToken) {
                    console.log('Token retrieved: ', currentToken);
                    this.saveTokenToDatabase(currentToken);
                    setCookie('fcm-token', currentToken, 300);
                    this.$router.push('/dashboard');
                } else {
                  console.log('No registration token available. Request permission to generate one.');
                }
              }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
              });
          } else {
            console.log('Unable to get permission to notify.');
          }
        });
        } catch (e) {
            console.log('Error requesting notification permission', e);
            }
      },
      enableNotifications() {
        this.requestNotificationPermission();
      },
      disableNotifications() {
        console.log('Notificações desativadas');
        const token = getCookie('fcm-token');
        api.post('/dashboard/api/fcm-token/delete/', { token })
        .then(response => {
            console.log('Token removido da API com sucesso.', response);
            // remove from cookie
            deleteCookie('fcm-token');
            
        }).catch(error => {
            console.error('Erro ao remover o token da API:', error);
            deleteCookie('fcm-token');
        });
        this.$router.push('/dashboard');
      }
    }
  };
  </script>
  
  <style scoped>
  .custom-emoji-icon {
    background-color: #25FF7C;
  }
  
  .push-notification-container {
    border-radius: 20px;
    padding: 20px;
    max-width: 400px;
    margin: auto;
  }
  
  .notification-preview {
    width: 100%;
  }
  
  .notification-image {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  
  .notification-label {
    font-size: 12px;
    color: black;
    margin-bottom: 8px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .start-button {
    background-color: #2555FF;
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .disable-button {
    background-color: #ff4e4e;
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .start-button:hover {
    background-color: #1e4ec1;
  }
  
  .cancel-button {
    background: none;
    border: none;
    color: #797180;
    cursor: pointer;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
  }
  </style>
  