<template>
    <div v-if="isVisible" class="mt-5 bg-[#F7F2FF] py-4 px-2 flex align-center rounded-[9px] relative">
        <button @click="hideComponent" class="absolute top-0 right-0 mt-2 mr-2 text-[#743BCD5C] font-bold">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.29521 4.9999L0.938965 8.35615L1.64386 9.06104L5.00011 5.7048L8.35635 9.06104L9.06124 8.35615L5.705 4.9999L9.06111 1.6438L8.35621 0.938904L5.0001 4.29501L1.644 0.938904L0.939105 1.6438L4.29521 4.9999Z"
                    fill="#743BCD" fill-opacity="0.36" />
            </svg>
        </button>
        <img src="@/assets/banner-image.svg" alt="Banner Image" />
        <div class="ml-1">
            <img src="@/assets/novidade.svg" alt="Novidade" />
            <p class="text-[12px] text-left mt-2">
                Explore o novo <b>dashboard de afiliados</b> da HypezBet.
            </p>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['affiliate_id'],
    data() {
      return {
        isVisible: true
      };
    },
    methods: {
      hideComponent() {
        this.isVisible = false;
      }
    }
  };
  </script>

<style scoped>
/* Estilos adicionais, se necessário */
</style>