<template>
  <div class="flex flex-col pt-[22px]">
    <FilterComponent @filter-applied="handleFilterApplied" />
    
    <!-- Cards de pontuação do usuário 2 por linha -->
    <div class="grid grid-cols-2 md:grid-cols-2 gap-2 mt-2">
      <UserScoreCards :image="'indicados.svg'" :loading="stats.loading" :value="stats.indicated_users_count" :text="'Usuários indicados'" />
      <UserScoreCards :image="'ftd.svg'" :loading="stats.loading" :value="stats.ftd_count" :text="'FTD'" />
      <UserScoreCards :image="'total-deposito.svg'" :loading="stats.loading" :value="stats.total_deposit_value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })" :text="'Valor de depósitos (R$)'" />
      <UserScoreCards :image="'ganhos.svg'" :loading="stats.loading" :value="stats.deposit_count" :text="'Qtd. de depósitos'" />
    </div>
  </div>
</template>

<script>
import api from '@/services/api'; // Importe a instância configurada do Axios
import UserScoreCards from './UserScoreCards.vue';
import FilterComponent from './FilterComponent.vue';
import { format } from 'date-fns';

export default {
  name: "DailyCards",
  props: ['affiliate_id'],
  components: {
    UserScoreCards,
    FilterComponent
  },
  data() {
    return {
      stats: {
        indicated_users_count: 0,
        ftd_count: 0,
        total_deposit_value: 0,
        deposit_count: 0,
        loading: true
      },
      startDate: null,
      endDate: null
    };
  },
  async created() {
    await this.fetchDashboardStats();
  },
  watch: {
    affiliate_id: {
      handler() {
        this.fetchDashboardStats(this.startDate, this.endDate);
      },
      immediate: true
    }
  },
  methods: {
    async fetchDashboardStats(start_date = null, end_date = null) {
      try {
        const params = {};
        this.stats = {
          indicated_users_count: 0,
          ftd_count: 0,
          total_deposit_value: 0,
          deposit_count: 0,
          loading: true
        };
        if (start_date && end_date) {
          params.start_date = format(new Date(start_date), 'yyyy-MM-dd');
          params.end_date = format(new Date(end_date), 'yyyy-MM-dd');
        }
        if (this.affiliate_id) {
          params.affiliate_id = this.affiliate_id;
        }
        console.log('Params:', params);

        const response = await api.get('/dashboard/api/dashboard-stats/', { params });
        if (response.status === 200) {
          this.stats = response.data;
          this.stats.loading = false;
        }
      } catch (error) {
        console.error('Erro ao obter as estatísticas do dashboard:', error);
      }
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy');
    },
    handleFilterApplied({ start, end }) {
      console.log('Date Range Applied:', start, end);
      this.startDate = start;
      this.endDate = end;
      this.fetchDashboardStats(start, end);
    }
  }
};
</script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */
</style>
