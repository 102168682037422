// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyAiIdin3dGQuXVrzZe6c_hnH8V7Swo2TRs",
    authDomain: "rocket-digital-infra.firebaseapp.com",
    databaseURL: "https://rocket-digital-infra-default-rtdb.firebaseio.com",
    projectId: "rocket-digital-infra",
    storageBucket: "rocket-digital-infra.appspot.com",
    messagingSenderId: "793101618749",
    appId: "1:793101618749:web:763a40c6606ed519af6454",
    measurementId: "G-9ZSXRF245B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app)

let messaging = null;
try{

  messaging = getMessaging(app);
  
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Customize notification handling here if needed
  });
}catch(e){
  console.log(e)
}

const database = getDatabase(app);

export { messaging, getToken, onMessage, database, analytics };