import { createRouter, createWebHistory } from 'vue-router';
import LoginView from './views/LoginView.vue';
import DashboardView from './views/DashboardView.vue';
import AddToHomeView from './views/AddToHomeView.vue';
import PushNotificationView from './views/PushNotificationView.vue';

const routes = [
  { 
    path: '/', 
    component: LoginView,
  },
  { 
    path: '/dashboard', 
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  { 
    path: '/push-notification', 
    component: PushNotificationView,
    meta: { requiresAuth: true }
  },
  { 
    path: '/add-to-home', 
    component: AddToHomeView 
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next('/');
  // } else if (to.path !== '/add-to-home') {
  //   // const isPWA = window.matchMedia('(display-mode: standalone)').matches;
  //   const isPWA = true;
  //   if (!isPWA) {
  //     next('/add-to-home');
  //     // next();
  //   } else {
  //     next();
  //   }
  } else {
    next();
  }
});

export default router;
