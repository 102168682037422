

function getCookie (name) {
    // const value = `; ${document.cookie}`;
    // const parts = value.split(`; ${name}=`);
    // if (parts.length === 2) return parts.pop().split(';').shift();
    //localStorage
    return localStorage.getItem(name);
}

function setCookie (name, value, days) {
    days
    // let expires = '';
    // if (days) {
    //     const date = new Date();
    //     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    //     expires = `; expires=${date.toUTCString()}`;
    // }
    // document.cookie = `${name}=${value || ''}${expires}; path=/`;
    //localStorage
    localStorage.setItem(name, value);
}

function deleteCookie (name) {
    // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    //localStorage
    localStorage.removeItem(name);
}

export { getCookie, setCookie, deleteCookie };