<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-100">
      <div class="p-6 max-w-sm w-full bg-white rounded-lg">
        <div class="text-center mb-4">
          <img src="@/assets/logo.png" alt="Logo" class="mx-auto mb-4 w-24" />
        </div>
        <div class="mb-4">
          <input
            v-model="username"
            type="text"
            placeholder="Nome de usuário"
            class="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div class="mb-4">
          <input
            v-model="password"
            type="password"
            placeholder="Senha"
            class="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <button @click="login" class="w-full bg-blue-500 text-white py-2 rounded-lg">Acessar</button>
      </div>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  import { getCookie, setCookie } from '@/services/cookie';
// import { get } from 'core-js/core/dict';
  
  export default {
    name: "LoginForm",
    data() {
      return {
        username: '',
        password: ''
      };
    },
    mounted() {
      const token = getCookie('token');
      console.log('LoginForm mounted', token);
      if (token) {
          api.post('/accounts/api/token/verify/', { token })
          .then(response => {
            if (response.status === 200) {
              this.$router.push('/dashboard');
            }
          }).catch(error => {
            console.error('Token verification failed:', error);
            // Opcional: remover token inválido do localStorage
            localStorage.removeItem('token');
          });
      }else{
        console.log('Token not found');
      }
    },
    methods: {
      async login() {
        try {
        const apiUrl = process.env.VUE_APP_API_URL;
        console.log('API URL:', apiUrl);
        const response = await api.post('/accounts/api/token/', {
            username: this.username,
            password: this.password
          });
          
          const token = response.data.access;
          console.log('Token:', token);
          setCookie('token', token, 300);
          // setCookier for affiliate_id
          const payload = token.split('.')[1];
          const decodedPayload = atob(payload);
          const parsedPayload = JSON.parse(decodedPayload);
          console.log(parsedPayload);
          setCookie('affiliate_id', parsedPayload.affiliate_id, 300);

          this.$router.push('/dashboard');
        } catch (error) {
          console.error('Error logging in:', error);
          alert('Credenciais inválidas');
        }
      }
    }
  };
  </script>
  
