// src/registerServiceWorker.js
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        // .then((registration) => {
        //     console.log('Service Worker registrado com sucesso:', registration);
        // })
        // .catch((err) => {
        //     console.error('Falha ao registrar o Service Worker:', err);
        // });
}
